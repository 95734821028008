import ItemImages from 'src/store/dreamdiner/data/demoitemimages'

export default {








	general: {
		yes: "Yes",
		no: "No",
		yesNo: "Yes or No",
		add: "Add",
		addCredits: "Add Credits",
		color: "Assigned color",
		colorBg: "Background color",
		assigned_icon: "Assigned icon",
		assigned_items: "Assigned items",
		back: "Back",
		confirm: "Confirm",
		continue: "Continue",
		cancel: "Cancel",
		clear: "Clear",
		close: "Close",
		day: "Day",
		delete: "Delete",
		duplicate: "Duplicate",
		ends: "Ends:",
		finish: "Finish",
		from: "From",
		loading: "Loading...",
		mobile: "Mobile number",
		name: "Name",
		next: "Next",
		none: "None",
		price: "Price",
		verify: "Verify ",
		optional: "Optional",
		save: "Save",
		send: "Send",
		restart: "Restart",
		resetAll: "Reset All",
		start: "Start",
		select: "Select",
		selectDate: "Select Date",
		starts: "Starts:",
		title: "Title",
		to: "To",
		update: "Update",
		learnMore: "Learn More",
		assigned_itemsCount: "Item Count: {count}",
		no_results: "No Results",
		restore: "Restore",
		daily: "Useful",
		system: "System",
		noItemsAssigned: "No items found assigned",
		chooseSearchMethod: "Choose search method",
		chooseSearchMethodFull: "Contains the word...",
		chooseSearchMethodStart: "Starts with",
		searchItem: "Search Item",
		saveInfo: "Save your changes",
		resetInfo: "Reset changes",
		overviewBtnSettings: "Business Info & Settings",
		overviewBtnActivity: "Business Activity Hours",
		accordionExpansionLearnMoreTitle: "Read more about this section",
		downloadAppWaiterText: "The login username will be the mobile number of the Waiter",
		downloadAppStaffText: "The login username will be the mobile number of the Staff Member",
		active: "Active",
		step01: "Step 1",
		step02: "Step 2",
		step03: "Step 3",
		step04: "Step 4",
		step05: "Step 5",
		step06: "Step 6",
		step07: "Step 7",
    option_01: "Option 1",
    option_02: "Option 2",
    option_03: "Option 3",
    option_04: "Option 4",
    doThis: "Do this",
	},





	app: {
		headsup: "Heads up!",
		// loading: "Loading...",
		errorMessage: "Something went wrong...",
		errorSaveMessage: "Save Error: Input data is not valid...",
		never: "[No data yet]",
		empty: "No results",
		autocomplete: "Select or start typing",
		phonePlaceholder: "Digits only",
		phoneHint:
			"Only numbers (0-9), no hyphens (-), no spaces, no dots (.), no / ( ), max. 20 characters.",
		verified: "Verified",
		fail: "Oops. Something went wrong",
		registeredSupervisorMobileExists:
			"A registered Supervisor with this mobile number already exists",
		registeredWaiterMobileExists:
			"A registered Waiter with this mobile number already exists",
		registeredStaffMobileExists:
			"A registered Staff Member with this mobile number already exists",
		itemSpecialPriceAlert:
			"Fix Special Price expiration date or delete the date. Then try again.",
		itemInvalidParams:
			"Some data is invalid or missing. Please check again",
			days: {
			everyday: "Every Day",
			sunday: "Sunday",
			monday: "Monday",
			tuesday: "Tuesday",
			wednesday: "Wednesday",
			thursday: "Thursday",
			friday: "Friday",
			saturday: "Saturday",
		},
		localeSelect: "Select language",
		timezoneSelect: "Select timezone",
		countrySelect: "Select country",
		version: "Version: {version}",
		deleteConfirm: "Are you sure you want to delete this {object}?",
		unsavedChanges: "Close this form? Any changes made will be unsaved.",
	},
	auth: {
		emailVerification: {
			title: "Please verify your email",
			subtitle:
				"Enter your email below, and we\'ll send you an email with a verification link. Click the link in the email to proceed.",
			resendTitle: "Do you need us to resend the verification link?",
			resendCta: "Resend link",
			resent: "Verification email sent successfully",
			success: "You\'ve completed the email verification process.",
		},
		login: {
			title: "Welcome to DreamDiner",
			titleWithName: "Welcome to DreamDiner {name}!",
			subtitle: "Log in to start your experience",
			confirm: "Log in",
			noUser: "Please first sign up to continue",
			unverified:
				"Please verify your email address to continue.\nSent to: {email}. Check spam folder if needed.",
			fail: "Invalid username/password",
			success: "Logged in successfully",
			email: "Enter your email address",
			password: "Enter your password",
			forgot: "I forgot my password",
			register: "I want to sign up",
			resendVerification: "Resend verification email",
			changeUser: "Not {user}?",
		},
		forgot: {
			title: "Forgot your password?",
			subtitle:
				"Enter your email address, then check your inbox (or SPAM folder!) to get a new password reset link",
			login: "Remembered my password! Back to sign in",
			register: "Back to sign up",
			confirm: "Send link",
			success: "Reset link sent successfully",
			profileResetPassword: "Reset Password",
			profileResetPasswordSent: "Reset link sent to your email",
			errors: { 205: "No user found." },
		},
		register: {
			title: "Welcome to DreamDiner",
			subtitle: "Sign up to start your experience",
			email: "Enter your email address",
			emailConfirm: "Re-enter your email address",
			phone: "Enter a mobile number",
			password: "Enter your password",
			passwordConfirm: "Re-enter your password",
			name: "What\'s your name?",
			existingUser:
				"A user with your email or phone number has already signed up",
			success:
				"Welcome to DreamDiner! Check your inbox to verify your email address to continue.",
			resend: "Resend verification code ({counter})",
			fail: "Oops. Something went wrong",
			// submitName: "Complete",
			confirm: "Sign up",
			login: "I already signed up",
			otpTitle: "Type in your code",
			otpSubtitle: "We sent a verification code to {email}",
			incorrectOTP: "Sorry. Wrong code. Please try again or Resend",
		},
		// verification: {
		// 	welcome: "Welcome back, {name}!",
		// 	title: "Wait for your SMS verification code",
		// 	subtitle: "Enter the 6-digit code below to sign in",
		// 	termsPrompt: "termsPrompt",
		// 	termsCta: "termsCta",
		// 	wrongNumberPrompt: "SMS was sent to {number}",
		// 	wrongNumberCta: "Change and resend?",
		// },
	},
	autocompleteInput: { defaultPlaceholder: "Start typing..." },

	tooltips: {
		infoAdminCode:
			"This is not a login password! Recommended to change this code on a daily or weekly basis",
		tableMasterCode:
			"Default table code if admin has given no specific code",
		infoBusinessWebsite:
			"Mainly for Clients use to navigate to your website easily. Copy the URL link precisely as shown in your browser",
		registrationPhone: "Needed to log in to the mobile app",
		waitersAssignTables: "Multi table selection enabled",
		itemKitchens:
			"This item will be linked to the selected Prep Station",
		itemCategories:
			"This item will always show under these selected categories. Multiple categories are allowed. E.g., FishBurger can be under Burgers and under Seafood",
		itemLabels:
			"A general classification of this item. Multiple selections allowed",
		itemAllergens: "Multiple selections allowed",
		itemSymbol: "A 3-letter code for PREP App use",
		categoryName: "Preferably, a single-word title",
		menuPrice:
			"Link this item to menus and set a special price for each. If not set, the Default price is given",
		settingsCog: "All your business Info and Settings",
		activityHours: "Your business Daily and Weekly Activity Hours",
		businessStatusLive: "If Hidden, then clients can reach the business only by QR code or direct URL link",
		addTemplateBtn: "Make sure to ADD on the form that will open on the right",
		colorWaiter: "All items ordered from this waiter will have this waiter\'s color",
		colorTerminal: "All items related to this Station will have this station\'s color",
		colorStaff: "Assign a color to this staff member",
		colorMenu: "Assign a color to this menu, click ADD, and watch the graph on the left",
		addBtnHint: "Click here to add to your list",
		itemCategoryAlert: "Must link to one or more categories",
		settingsBlock: "Features & Settings",


		categories: {
			cat_100: "Beer",
			cat_101: "Beer",
			cat_102: "Beer-tap",
			cat_103: "Cold Drink",
			cat_104: "Drink",
			cat_105: "Tea",
			cat_106: "Coffee",
			cat_107: "Wine",
			cat_108: "Champagne",
			cat_109: "Alcohol",

			cat_110: "Croissants",
			cat_111: "Bread",
			cat_112: "Pretzel",
			cat_113: "Cereal",
			cat_114: "Eggs",
			cat_115: "Breakfast",
			cat_116: "Salad",
			cat_117: "Tomato",
			cat_118: "Soup",
			cat_119: "ToGo",

			cat_120: "Pizza",
			cat_121: "Pizza",
			cat_122: "Fries",
			cat_123: "Sandwich",
			cat_124: "Sushi",
			cat_125: "Sushi",
			cat_126: "Wrap",
			cat_127: "Noodles",
			cat_128: "Rice",
			cat_129: "Children",

			cat_130: "Burgers",
			cat_131: "Grill",
			cat_132: "Fish",
			cat_133: "Fish",
			cat_134: "Chicken",
			cat_135: "Kebab",
			cat_136: "Barbecue",
			cat_137: "Hotdog",
			cat_138: "Seafood",
			cat_139: "Take-Away",

			cat_140: "Fruit",
			cat_141: "Cake",
			cat_142: "Cake",
			cat_143: "Ice-cream",
			cat_144: "Ice-cream",
			cat_145: "Sundae",
			cat_146: "Muffin",
			cat_147: "Candy",
			cat_148: "Dessert",
			cat_149: "Waffles",

			cat_150: "Dinner",
			cat_151: "Meal",
			cat_152: "Knife-and-Fork",
			cat_153: "Cloche",
			cat_154: "Chef Special",
			cat_155: "Resaurant",
			cat_156: "Lunch",
			cat_157: "Tray",
			cat_158: "Cafeteria",
			cat_159: "Cutlery",
		}
	},

	business: {
		adminProfile: {
			profileTitle: "User Profile",
			profileSubTitle: "Set here your personal information",
			name: "Name",
			nameHint: "Enter your name here",
			mobile: "Mobile",
			mobileExplanation: "Your mobile number will be used to log in the Admin App",
			userPhoto: "Photo",
			userPhotoExplanation: "Make it a good one :)",
			changePassword: "Change Password",
			changePassword2: "Click the Reset button to change your password, then check your incoming emails. If you don\'t receive one, you might also need to check the SPAM folder",
		},
		business: {
			title: "Business Name",
			titlePlaceholder: "Your business name",
			titleDescriptionPlaceholder: "Another DreamDiner Business",
			titleWebsitePlaceholder: "https://www.your-business.com",
			titleEn: "English Business Name",
			titleEnPlaceholder: "Your English business name",
			titleEnExplanation: "SMS received will be under this sender name",
			statusDemo: "DEMO Business",
			statusLive: "LIVE",
			statusHidden: "Hidden",
			switchLive: "Set to LIVE",
			switchHidden: "LIVE",
			email: "Business Email",
			emailSubtitle: "This email will also receive a copy backup of all orders",
			emailPlaceholder: "Your business email",
			emailVerifyText: "OK. Your business mail is the same as your user email",
			emailVerifyBtn: "Verify now",
			useMyEmail: "Use my login email",
			adminPassword: "Admin Passcode",
			adminPasswordSubtitle:
				"This passcode is used as a security measure to allow only authorized users with the password to carry out various tasks such as issuing refunds, deleting orders, etc. It can be changed on a daily or weekly basis.",
			enterNewPassword: "Enter New Password",
			pin: "Client Master Verification Code",
			pinSubtitle:
				"Use this code to provide access to a client who cannot be mobile verified for any reason. Do not regularly use this code and change it often. The default is 999",
			pinPlaceholder: "Digits only",
			// social: "Online Presence:",
			website: "Your Business Website",
			websitePlaceholder: "https://...",
			timezone: "Timezone",
			timezoneSubtitle: "It might need adjustment according to Summertime and Wintertime",
			country: "Country",
			currency: "Currency",
			tagline: "Business Tagline",
			taglineExplanation: "A concise description that will appear under your business name in the Client app",
			hours: "Business Hours",
			currentTime: "Current Business Time: ",
			adminPasswordPlaceholder: "6 digits minimum, digits only",
			adminPasswordRepeatPlaceholder: "Repeat passcode",
			businessLogo: "Business Logo",
			businessLogoSubtitle: "If left empty, the DreamDiner logo will be displayed as the default image for items without a photo or uploaded image.",
			logoSelectDemoBtn: "Select Demo Logo",
      logoSelectSampleBtn: "Select a temporary image",
			logoSelectOr: "Or:",
			logoSelectTitle: "Select from a given demo logo",
			logoSelectSubtitle: "Later, you will replace it with your logo",
			openinghoursClosed: "Closed",
			openinghoursAllDay: "24hr.",
			addTimeSegment: "Add Time Segment",
			addTimeSegmentExplan: "Each day must have at least one time segment. Please do not overlap and keep time segments so the first is the earliest of the day.\nTo add an early segment, delete daily segments and edit the first to be set as the earliest.",
			updateMessageSnackBar: "Changes successfully updated",
			businessColorTitle: "Business Theme Color",
			businessColorSubtitle:
				"Your preferred color theme that best matches your business vibe and logo",
			businessLocationTitle: "Business Location",
			businessLocationSubtitle:
				"Needed so nearby clients could easily find your business in the Client App",
			businessLatitude: "Latitude:",
			businessLongitude: "Longitude:",
			getLocationFromDevice: "Set Location for me",
			getLocationAccessDenied: "Check your browser settings and enable location access",

      noTrackerEnableTitle: "Client Tracking Progress (Usually for non-restaurant businesses)",
      noTrackerEnableDesc: "The Client App features a tracking option component for the order preparation process (Ordered, in Progress, Ready, Delivered). If your business does not handle a preparation process or you do not want your clients to be exposed to this status tab after executing their order, you can decide to hide this component",
      noTrackerSwitchEnabled: "Clients will track the preparation process (Restaurant Mode)",
			noTrackerSwitchDisabled: "Client App will not expose the preparation status tab",

			businessEatInTitle: "Eat-In Options",
			EatInSwitchEnabled: "Eat-In button is visible in Client App",
			EatInSwitchDisabled: "Eat-In is now hidden",
			businessAllowEatInSubtitle:
				"For example, if you have a business that runs only Take-Away and Delivery, you can decide to hide this button on the Client App ",
			EatInMinimumTitle: "Eat-In Order Minimum:",
			EatInMinimum: "Set a price value",
			businessEatInMinimumSubtitle:
				"Set your minimum price value to accept Eat In orders. Value must be >6",

			businessTakeAwayTitle: "Take-Away Options",
			TakeAwaySwitchEnabled: "Take-Away button is visible in Client App",
			TakeAwaySwitchDisabled: "Take-Away is now hidden",
			businessAllowTakeAwaySubtitle: "If enabled (green) – then Take-Away button in the Clients app is enabled according to the defined start and end time",
			takeAwayMinimumTitle: "Take-Away Order Minimum:",
			takeAwayMinimum: "Set a price value",

			businessDeliveryTitle: "Delivery Options",
			DeliverySwitchEnabled: "Delivery button is visible in Client App",
			DeliverySwitchDisabled: "Delivery is now hidden",
			businessAllowDeliverySubtitle:
				"If enabled (green) – then Delivery button in the Clients app is enabled according to the defined start and end time",
			deliveryMinimumTitle: "Delivery Order Minimum:",
			deliveryMinimum: "Set a price value",

			tablePlannerTitle: "Table Planner Settings",
			tablePlannerDesc: "Some basic settings for your Table Planner",
			tablePlannerEnableWeb: "Enable Table Planner for Web",
			tablePlannerLinkBtn: "Open Table Planner in new tab",
			tablePlannerEnableWebDesc: "Table Planner Booking for Web will enable your clients to place a reservation from their desktop or mobile directly, and you will instantly see these reservations on the Table Planner App mainboard",
			EnableWebSwitchEnabled: "Clients can now make self-reservations in Table Planner for Web",
			EnableWebSwitchDisabled: "Your business is hidden from search in Table Planner for Web App",

			tablePlannerFirstDay: "Set preferred first day of the week",
			tablePlannerFirstDaySun: "Sunday",
			tablePlannerFirstDayMon: "Monday",
			tablePlannerFirstDayDesc: "Determines mainboard\'s first day",
			tablePlannerIntervals: "Mainboard Intervals",
			tablePlannerIntervalsDesc: "Set mainboard\'s columns intervals",
			tablePlannerMainboardDefaultView: "Mainboard Default View",
			tablePlannerMainboardDefaultViewDesc:
				"When mainboard loads, which view to show by default",
			tablePlannerReservationDefaultTime: "Reservation Default Time",
			tablePlannerReservationDefaultTimeDesc:
				"Determines by default the reservation end time",
			paymentsPaymeSettingsTitle: "PayMe Settings",
			paymentsPaymeSettingsTitleDesc:
				"Settings needed to accept payments",
			paymentsPaymeSettingsTitle: "PayMe Settings",
			paymentsPaymeSettingsIdPlaceholder: "Enter MPLXXXXX-XXXXXXXX-XXXXXXXX-XXXXXXXX here",
			paymentsPaymeSettingsIdTooltip:
				"This ID is generated after you sign for payments",
			paymentsAcceptApplePayTitle: "Accept Apple Pay",
			paymentsSellerIdTitle: "Seller ID",
			paymentsSellerIdTitleDesc: "Your PayMe Seller ID",
			paymentsSellerIdDebug: "Seller ID Debug",
			paymentsSellerIdDebugSample: "Copy and place this in DEV mode only: MPL16444-14303SH9-AJLRRH1F-YU1W0IAH",
			paymentsAcceptBitTitle: "Accept Bit Payments",
			paymentsAcceptBitTitleDesc: "Enable your clients to pay using their Bit App.\n First, enable the Bit App in your Payme dashboard (Payme Sidebar > Admin > Apps Marketplace); Then, enable this switch.\n Note: 0.3 NIS will be added to each transaction",
			paymentsBitSwitchEnabled: "Bit payments enabled for your clients",
			paymentsBitSwitchDisabled: "Bit payments are disabled",
			paymentsAcceptIcountTitle: "Enable iCount Invoices for your Clients",
			paymentsAcceptIcountTitleDesc: "Enable your clients to download their purchase invoices.\n First, enable the iCount App in your Payme dashboard (Payme Sidebar > Admin > Apps Marketplace); Then, enable this switch.\n Note: 0.35 NIS will be added to each transaction",
			paymentsIcountSwitchEnabled: "iCount invoices are enabled for your clients",
			paymentsIcountSwitchDisabled: "iCount invoices are disabled",
		},
    plans: {
			plansTitle: "Available Business Plans",
			plansSubTitle: "Choose the best that fits your business",
			plansItalTitle: "Plans change automatically accordingly once set as described",
			plansBtnToSetPayments: "Set your Payments Gateway here",
			learnMoreBtnSite: "https://dreamdiner.io/start-he/#plans",

      plansStep1Chip: "Step 1",
      plansStep2Chip: "Step 2",
      plansStep1Title: "Credits Plan (or stay on FREE)",
      plansStep2Title: "Set Payments",

      howWorksTitle: "How it works?",
      howWorksInfo: "Understand what is best for your business",
      howWorksLine01: "Any business can upload its menu items and create a digital menu for customers to access using a generated QR code. Additionally, businesses can share links through the admin dashboard.",
      howWorksLine02: "To receive and manage orders, businesses need to have a positive credit account. Credits can be purchased as a pay-as-you-go package or as an unlimited monthly subscription.",
      howWorksLine03: "To accept payments and enable customers to pay for their orders (including takeout and delivery orders where upfront payment is necessary), businesses need to sign up and create a Stripe account through the DreamDiner platform. In this scenario, all sales funds are deposited directly into the business account, with regular Stripe fees and a 1.2% commission to the DreamDiner platform deducted.",

      planFreeTitle: "Menu",
      planCreditTitle: "Credits",
      planPaymentsTitle: "Unlimited",
      planGetPaidTitle: "Get Paid",

      planFreeInfo: "FREE Plan",
      planCreditInfo: "Credit Balance: {number}",
      planPaymentsInfo: "Renews at: {date}",
      planPaymentsNotSubscr: "Not Subscribed",
      planGetPaidInfo: "Boost and secure your sales!",

      planFreeLine01: "A default plan is in place when no other plan is active",
      planCreditLine01: "A Pay-as-you-go plan where 1 credit = 1 order. Credit packages are one-time payments, and your quota will not expire. You can buy an amount of order credits and use them whenever you like (pay as you go).",
      planPaymentsLine01: "A monthly subscription recommended for businesses with over 500 monthly orders. Subscriptions charge you every month for a fixed amount of order credits. This plan offers peace of mind to your business. You won\'t have to worry about constantly topping up credits or dealing with a locked system when your business runs out of credits in the middle of work. Monthly orders are unlimited, and your previous credit balance (if there is one) remains frozen and unaffected",
      planGetPaidLine01: "Enrolling in the Get Paid program allows clients to make direct payments within the system, eliminating the need for the business to handle payments and ensuring that orders, especially for take-aways and deliveries, are securely paid for",

      whenFreePlan: "Switched automatically when credit balance reaches 0 (Zero) and no subscription is active",
      whenCreditPlan: "Automatically is set when the credit balance is positive (>1) and the subscription is not active",
      whenPaymentsPlan: "This plan is automatically activated upon subscription, and the credit balance remains unaffected",
      planGetPaidPlan: "This plan is a powerful addition for businesses with either the Credits or Unlimited plan. It enables client payments and ensures seamless and secure order",

      explanFreePlan: "This fall-back plan is automatically activated when no other plan is set, and no business credits are available. And still, a free digital menu for up to 200 items to share with your clients is always available",
      explanCreditPlan: "A simple pay-as-you-go plan means every order made equals one credit. Every time your credit balance reaches the minimum set, top up your credits manually",
      explanGetPaidPlan: "This full-featured plan is set automatically once subscribed and by setting a unique button link to your preferred payments provider to directly receive payments to your account from all client orders, including the take-away and delivery selling options",

      planFreeClient: "Clients can view the menu, but they cannot place orders",
      planCreditClient: "Clients can place orders as long as your business credit balance is greater than 1",
      planPaymentsClient: "Businesses can accept an unlimited number of orders per month",
      planGetPaidClient: "Clients can complete their orders by making payments directly",

      benefitMenu: "Full Digital menu",
      benefitOrders: "Manage orders in Admin Dashboard",
      benefitPrepApp: "Manage orders in Prep-App",
      benefitSetPaid: "Admin set orders as PAID",
      benefitPayments: "Enable client paying for their orders",
      benefitUnlimited: "Unlimited Orders",

      price100: "100 Credits = $10 (0.1)",
      price500: "500 Credits = $44 (0.088)",
      price1000: "1000 Credits = $150 (0.15)",
      priceSubs: "Subscription = $44/month",
      priceGetPaid: "Fees = Stripe usually charges 2.9% + 30¢ for each successful card charge + 1.2% DreamDiner fee per each order",

      creditTerms: "Credits are valid for 1 year from purchase date",
      subscriptionTerms: "Cancel anytime, the subscription remains active until the next renewal date",
      getPaidTerms: "Cancel anytime by deactivating your linked Stripe account",

      btnGetMoreCredits: "Get more credits",
      btnUnlimited: "Go Unlimited",
      btnUnlimitedifSubscribed: "Mange your subscription",
      btnGetPaid: "Enrol for GetPaid",
			siteLinkPayme: "https://dreamdiner.io/he-payment/",

      asterisk01: "The business accepts payments using its current payment system and manually marks orders as PAID in the orders card or in the PREP-APP (DreamDiner Preparation App)",
      asterisk02: "Depends if the business has enrolled to GetPaid",
      },

		credits: {
			buyCreditsTitle: "Buy credits - Pay as you go",
			buyCreditsText1: "1 Credit = 1 Order",
			buyCreditsTextBtn: "Show All Business Purchases",
			currentBalance: "Current credit balance: {balance}",
			lastCreditPurchase: "Last credit purchase: {date}",
			underCreditsBtn: "adds {credits} credits to your account",
			subscribeTitle: "Be subscribed - Unlimited use.",
			subscribeText: "Monthly subscription, cancel anytime.",
			lastRenewal: "Subscription renewal at: {date}",
      subscrEnds: "Subscription ends at: ",
      backToPlansPageBtn: "Back to Plans Page",
			underSubscribeBtn: "Buys a subscription",
			beforeMinimum:
				"Set an alert when your credit balance drops under a minimum",
      minimumLabel: "Alert me whenever credits drop below:",
      purchasePageTitle: "List of Business Credits Purchases",
      purchasePageFreeCredits: "FREE Credits",
      purchasePageTotalPurchased: "TOTAL Purchases",
      purchasePageTotalOrders: "TOTAL Orders",
      purchasePageCreditBalance: "Credits Balance",
      },
    fixPlanPage: {
      title: "Subscribe to Unlimited Plan",
      subTitle: "Peace of mind: no worries about running out of Credits",
      titleIsSubscr: "Subscription Settings",
      btnSubscr: "Get Subscribed",
      subscrStatus: "Subscription status: ",
      subscrStatusActive: "Active",
      subscrStatusCanceled: "Canceled",
      nextPayment: "Next payment date: ",
      activeUntil: "Subscription active until: ",
      subscrInvalid: "Subscription is invalid. Please resubscribe in the Customer Portal",
      btnCustomerPortal: "Visit Your Subscription Customer Portal",
      CustomerPortalExplan: "Manage all your personal data, payment methods, cancellations, renewals, and more here.",
      },
    getPaidPage: {
      title: "Enroll to receive payments",
      subtitle01: "Please select your preferred payment provider gateway here and open a connected account under the DreamDiner platform. This will enable clients to pay for their orders, and funds will be deposited into your connected account",
      subtitle02: "Later, you can visit the Sales page to view your payments dashboard and transactions",
      ifGermanyTitle: "Based on your business location - Germany, choose your payment provider",
      ifIsraelTitle: "Based on your business location - Israel, choose your payment provider",
      btnStripSetupAccount: "Setup a Stripe Account",
      btnStripCreateAccount: "Create a Stripe Account",
      btnStripCreateConnected: "Connected as: bus@bus.com",
      btnSoon: "Available Soon",
      learnMoreStripeLink: "Learn more about Stripe Connect",
      learnMoreStripeHref: "https://stripe.com/en-de/connect",
      learnMorePayMeLink: "Learn how to set a PayMe account",
      learnMorePayMeHref: "https://dreamdiner.io/he-payment/",
      stripe: "Stripe",
      stripeEnabled: "Stripe payments are enabled!",
      btnGoToSalesPage: "Visit your Stripe dashboards",
      paypal: "PayPal",
      payme: "PayMe",
      sepa: "SEPA",
      amazon: "Amazon",
      bitcoin: "Bitcoin",
      paymentsDisabled: "Payments Disabled",
      countries01: "Countries available: Worldwide",
      countries02: "Countries available: USA, Israel",
      countries03: "Countries available: Europe",
      countries04: "Countries available: USA, Europe",
      },
    qrPage: {
      title: "How can your clients place their orders?",
      subTitle: "All client orders can be managed later in Dashboard > Orders",
      option01Qr: "QR Code",
      option01QrExplan: "Share or print this QR code wherever your clients can access (front door, table, etc.). Scanning this code will guide the client directly to your business to place an order.",
      option02Web: "Web URL",
      option02WebExplan: "This URL is for client-side orders. Copy and paste it into any browser (desktop or mobile) or attach it to a button on your website. ",
      option02WebExplan02: "Note: This link is ALWAYS active, even if your business IS NOT set to LIVE",
      option02WebExplan03: "Or simply start a new order in a new tab by clicking the button below.",
      option03App: "Client App",
      option03AppExplan: "The DreamDiner Client app allows users to easily find your business through text search or location.",
      option03AppExplan01: "Note: Business is searchable only if set to LIVE",
      option04WL: "Private App",
      option04WLExplan: "A white-label app for your business, available for download on the Apple App Store or Google Play.",
      option04WLExplan01: "Clients have no access to other businesses besides yours",
      option04WLExplan02Yellow: "Subject to approval in App Store and Google Play",
     },
    salesPage: {
      title: "Manage your Sales Transactions",
      subtitle01: "Quickly view and control your Stripe payments and payouts dashboards",
      subtitle02: "Tab a tab to load your current dashboards. Might take some time to load",
      btnStripeLoadDashboards: "Load Stripe Dashboards",
      subtitle03: "Tab any line in that table for more info and actions (refunds, transaction details, etc.)",
      subtitle04: "Please set a connected Stripe account to activate the button.",
      getPaidLink: "Set your business to GetPaid",
      getPaidLink: "Set your business to GetPaid",
      paymentsTab: "Payments",
      paymentsTabExplan: "Shows a list of payments with export, refund, and dispute capabilities",
      payoutTab: "Payouts",
      payoutTabExplan: "Shows payout information and allows to perform payouts",
      },

    supervisors: {
        title: "Supervisors",
        subtitle: "Supervisors can access the admin dashboard but with certain limitations and restrictions",
        description:
          "Supervisors can perform various tasks within the system, such as overview orders, checking total sales, receiving payments, giving discounts, and more. Supervisors can access the dashboard with limited access to some features granted only to the system Admin.",
        redLine:
          "",
        redLine_wizard:
          "This section is optional, but it is recommended that at least one Supervisor be added. You can set yourself as well.",
        mySupervisors: "Your Supervisors:",
        addSupervisors: "Add Supervisor",
        supervisorName: "Supervisor\'s Name",
        supervisorEmail: "Supervisor\'s Email",
        moreSubtitle:
          "Supervisors\' image, logs, and more can be later changed in Settings",
      },
    tables: {
      title: "Tables",
      subtitle: "Efficiently deliver your clients\' orders, which are labeled according to the table name and color they were sent from.",
      description:
        "Tables play a vital role in the DreamDiner system by serving as important identifiers for client orders and the waiters serving them. The system will automatically create one behind the scenes if no tables are available. Each table is uniquely named and color-coded for easy identification of both the table, client, and the corresponding waiter. Additionally, tables are utilized in the Table Planner Booking App, an intelligent reservation and seating optimizer. It is recommended to keep table names to no more than four characters.",
      redLine:
        "This section is optional but recommended. A business does not need to have tables.",
      redLine_wizard:
        "This section is optional but recommended. A business does not need to have tables.",
      myTables: "Your Tables:",
      addTables: "Add Table",
      name: "Table\'s Name",
      namePlaceholder: "Max. 4 characters",
      seats: "Table seats",
      colorNote:
        "In real-time, the system will adjust table color according to the color of the waiter assigned to it",
      tablePin: "Table 6-digit Code",
      rbTitle: "Tables status in your business",
      rbExplan: "Choose one of the following options to specify the behavior of the client application regarding business tables.",
      rbOption01Title: "Tables and Codes: (Recommended) The business has tables and will utilize coding options for them.",
      rbOption01TitleClient: "Dreamliner encourages customers to place orders directly at the preparation stations, eliminating the need for waiters to take orders. It is recommended for customers to enter a 6-digit code to confirm their presence at the table when placing eat-in orders, as these orders are typically prepared before being paid for. In this scenario, the customer will select a table and input the 6-digit table code before submitting an order. This procedure doesn't apply to takeout and delivery orders. The table code can either be unique to each table or a general (master) code for all tables in the establishment. It's recommended to change this code regularly.",
      rbOption02Title: "No Codes: We have tables but do not need the coding system",
      rbOption02TitleClient: "In this case, the client will only select a table and will be able to submit the order",
      rbOption03Title: "No Tables: We do not have tables at all",
      rbOption03TitleClient: "In this case, the client will skip the table selection and coding pages",
      rbOption04Title: "Tables QR Code (Future Feature): Use a QR code for both table name and code",
      rbOption04TitleClient: "In this case, the client will just scan a code for both table name and code",
      alertTableStatusUpdated: "Business Table Status updated successfully",
      alertTableStatusError: "Business Table Status encountered an error. Please try again",
      tablePinExplanation: "Please place a sticker or another indicator on the table with this code. If no specific table code is assigned, the system will automatically use the table\'s Master Code. It\'s recommended to update these codes regularly.",
      tableMasterCodeTitle: "Tables Master Code",
      tableMasterCodeSubtitle: "This will be the fallback table code in case a table does not have a specific code defined",
      tableMasterCodeHint: "e.g. 456789 (6 digits)",
      tableMasterCodeSuccessMessage: "Master Code changed and saved successfully",
      tableMasterCodeErrorMessage: "Error saving or changing the Master Code",
      tableMasterCodePaletteLabel: "Uses Table Master Code",
      tablePicker: {
        title: "Waiter\'s Table Picker",
        subTitle: "Select a waiter, then tap any table to assign",
        waiters: "Your Waiters",
        tables: "Your Tables",
        alertNotAssigned: "There are unassigned tables!",
      },
    },
    waiters: {
      waiter: "Waiter",
      title: "Waiters",
      subtitle: "Waiters are assigned to your tables for better control over order delivery.",
      description:
        "Waiters play a crucial role in the service and efficiency of the restaurant\'s work. However, DreamDiner can significantly reduce their number and the restaurant\'s dependence on them.\nHere\'s why: client orders from the Client Apps are sent directly to the Prep Stations, with independent payment afterward. The Waiters App is mainly an efficient tool helping them deliver items declared as Ready to the hungry clients eagerly waiting.\nThe Waiter\'s App also enables menu access to take and make client orders.\nWaiters can be assigned to your tables, thus giving each order and table the waiter\'s color for better control and efficient order delivery.\nThe waiter will enter the Waiters App with the mobile number approved by you here.",
        redLine:
        "",
      redLine_wizard:
        "This section is optional, but it is recommended that at least one waiter be added. You can set yourself as well.",
      myWaiters: "Your Waiters List:",
      addWaiters: "Add Waiter",
      waiterName: "Waiter\'s Name",
      waiterPhoto: "Waiter\'s Photo",
      paletteTablesTitle: "Tables: ",
      tables: "Assigned tables",
      tablePickerBtn: "Assign Tables to Waiters",
      assignAll: "Assign to all tables",
      notAssignToTable: "Not assigned to any table",
      openPickerBtn: "Open Table Picker",
      moreSubtitle: " ",
      moreSubtitle_wizard:
        "Note: Waiters\' image, logs, and more can be later controlled in Dashboard > Waiters",
      // btnUpdate: "Apply",
    },
    kitchens: {
      title: "Prep (Preparation) Stations",
      subtitle: "Dividing the items into preparation stations eases the load and displays only the items associated with each station.",
      description:
        "A Prep Station is a designated area where a certain type of food is prepared. For example: Grill, Indoor Bar, Salad Preparations, etc.\nPlace a PREP App in every Prep Station, and set the selector accordingly. Your ordered items are now displayed where they are meant to be prepared: an ordered cocktail will show only in the app Prep Station set to Bar - and the Home Burger in the Grill Prep Station, etc. This selective routing significantly improves the accuracy and convenience of your preparations.\nPrep Stations are unlimited. \nMain Prep is a system default and holds by default all station items ordered.",
      redLine:
        "",
      redLine_wizard:
        "Main Prep is a system default and will display all ordered items regardless of their assigned stations.",
      myKitchens: "Your Prep Stations",
      addKitchens: "Add a Prep Station",
      kitchenName: "Prep Station\'s Name",
      // kitchenNumber: "Prep Station ID",
      kitchenPlaceholder: "Max. 3 characters",
    },
    staff: {
      title: "Staff Members",
      subtitle: "Staff members can log in to the PREP-APP to manage item preparation at their designated stations.",
      description:
        "A staff member can be, for example, a Chef, a Sous Chef, a Bartender, a Grill Man, etc. A staff member is also authorized to enter the PREP App - where all the preparations are conducted and delivered. \nIf preferred, assign each member to a Prep Station: a Bartender to the Bar station, the Sous-Chef to the Salad Preparations station, and so on. If not assigned, it will always show under the default Prep Station - Main Prep.\nThe staff member will enter the PREP App with the mobile number approved by you here.",
      redLine:
        "",
      redLine_wizard:
      "This section is optional but recommended to add at least one staff member. You can set yourself as well.",
      myStaff: "Your Staff Members:",
      addStaff: "Add Staff Member",
      staffName: "Staff Name",
      staffPhoto: "Staff\'s Photo",
      staffMobile: "Staff Mobile Number",
      kitchen: "Default Prep Station",
      // kitchenCheckbox: "Set as a master chef for this Prep Station",
      kitchenRoles: "Role",
      staffTerminalHint: "If empty, you might need to go back and add a Prep Station",
      color: "Assigned color",
      moreSubtitle_wizard:
        "Recommended: Staff image, logs, and more can be later controlled in Settings",
      staffSelectTerminal: "Assign to a Prep Station",
      staffSelectMainKitchen: "Main Prep",
    },
    categories: {
      title: "Categories",
      subtitle: "Categories are used to group relevant items and are then displayed on the menu landing page",
      description:
        "Category groups relevant items and then displays them in the menus, such as Burgers, Starters, Cocktails, and Hot drinks. Your items and dishes can be linked to one or more categories: For example, a Home Burger can be linked to the Burgers category, or to the Beef category, and to the Our Specials category! \nCategories are unlimited but try to be concise. \nYou can choose an icon from a provided set or upload your own icon or image to give your menu the perfect look and feel.",
      myCategories: "Your Categories",
      showIcons: "Show category icons",
      addCategories: "Add Category",
      categoryName: "Category Name",
      checkbox: "Hide from all menus",
      option_01: "Upload an image or an icon",
      option_02: "Choose an icon from a given set (background color as set in Step 1)",
      cancelItemCategory:
        "Unlink an item from this category only if it has at least one more category assigned",
      hasItems: "Some items are already linked to this category",
      hideFromMenu: "Hide this category from menus",
      hideFromMenuHidden: "Category is now hidden",
      hideFromMenuVisible: "Category is now visible",
      iconExplan: "Choose an icon that can be temporarily used and later be changed",
    },
    options: {
      title: "Options",
      subtitle: "Options are all the customization features that you enable your client to use when personalizing their ordered items.",
      description:
        "Item Extras are all personal adjustments you allow clients to request upon ordering an item.\nFor example: No onions, Add ketchup, Wrap to go, etc.\nEach Extra can be free of charge or have a specific price tag added to the total item price. Additionally, items can be linked to as many Extras as needed.",
      redLine: "Select from the given template or add your own.",
      redLine_wizard: "Select from the given template or add your own.",
      myExtras: "Your Item Options",
      addExtra: "Add Item Option",
      groupOptionsCount: "{count} options",
      freeNoCharge: "No Charge",
      groupToggle: "This is a Group Option",
      groupToggleDescription:
        "When options are grouped, the user can select only one option from the given set",
      optionTitle: "Option Title",
      optionNum: "Option #{num}",
      addGroupOption: "Add Option",
    },
    groupOptions: {
      title: "Group Options",
      subtitle: "Group Options are a set of options in which only one choice can be selected.",
      description:
        "A Group Option is a set of options where the client can select only one. For example, a degree in meat preparation is either Raw, Medium, or Well-Done. Another Group Option example is a single side dish selection: the client can choose only one - spaghetti, rice, or fries.\nEach option inside the Group Option can be free of charge or have a specific price tag added to the total item price.",
      redLine: "Select from the given template or add your own.",
      redLine_wizard: "Select from the given template or add your own.",
      myGroupOptions: "Your Group Options",
      addExtra: "Add A Group Option",
      groupOptionsCount: "{count} options",
      freeNoCharge: "No Charge",
      groupOptionsDrawerTitle: "Group Option Title",
      groupOptionsDrawerExplan: "Note: By default, the first option will be set as selected in Client App",
      groupOptionsDrawerNum: "Option #{num} (in this group)",
      addGroupOption: "Add an option to this group",
      groupBadge: "Group Option",
    },
    menus: {
      title: "Menus",
      subtitle: "Menus are used to adjust different item prices during business operating hours.",
      description:
        "Menus are dynamic and time-related, controlling the appearance of the items displayed in the Client\'s App. For example, Breakfast Menu between 08:00-12:00, and Happy Hour Menu between 17:00-19:00, etc.\nEach menu will display the relevant items linked to this menu. A specific item can be displayed across several menus. The items are displayed in each menu within their assigned category. For example, your Home Cocktail can be displayed in the Happy Hour Menu, in the Late night menu, but excluded from the Breakfast Menu. \nEach item can hold a different price tag on each menu where it is presented. Home Cocktail can be $6 on the Happy Hour Menu, but $9.5 on the Late Night Menu. Another possibility is setting a global discount to be applied to all items\' prices on that menu.\nThe menu timeline will give you a better visual display of all active menus at different times of the day.\nAvoid overlapping menus. \nMain Menu is the default menu when no other menu is available and displays all visible items.\nThe relevant menu is displayed in the Clients App when business is between working hours.\nMain Menu is the system default 24hr menu when no other menu is defined. When the business is defined as Closed, out of the working hours, your business menu in the Clients App will display, by default, the Main Menu with all items.",
      redLine:
        "",
      redLine_wizard:
      "This section is optional but recommended. A business does not need to hold menus.",
      myMenus: "Your Menus",
      addMenu: "Add Menu",
      hideMenu: "Hide menu",
      menuName: "Menu Name",
      startTime: "Start time",
      endTime: "End time",
      hasCategories: "This menu is linked to categories",
      itemsCount: "{count} items",
    },
    items: {
      title: "Items",
      subtitle: "Items refer to all the products available on your business menu, including dishes and drinks. Ensure they are engaging and of the highest quality!",
      description:
        "Finally, you are ready to open your first item! Now that we have our Prep Stations, our Menus, and Categories (feel free to go back and add more!) - we can link them all to create your perfect first item. Next, upload a beautiful and spectacular image of this item: tasty food starts with a delicious look. Here, you will also assign Allergens and Labels from a given list (multiple selections allowed) and set the Options and Extras.",
      myItems: "My Items",
      addItem: "Add item",
      hide: "Hide from all menus",
      symbol: "3-Letter Prep Code",
      itemDescription: "Description (up to {length} characters)",
      image: "Item / Dish Image",
      categories: "Link to your Categories:",
      menus: "Link to your Menus:",
      labels: "Add Labels: (Optional)",
      allergens: "Add Allergens: (Optional)",
      kitchens: "Prep Stations",
      kitchenTerminalHint: "Select Prep Station",
      kitchenTerminalMainKitchen: "Main Prep",
      price: "Listed Price:",
      menuPrice: "Menu Price:",
      discountPlaceholder: "No Special Price",
      regularOptions: "Regular options",
      groupOptions: "Group options",
      optionsAlertTooltip: "This option might have an Error",

      // extrasSelectPlaceholder: "Start typing...",
      addOption: "Create a new option",
      optionsCount: "Options: {num}",
      groupOptionsCount: "Groups: {num}",
      extras: "Options",
      duplicateMessage: "Item has been duplicated successfully!",
      linkToMenu: "Set a different price per each custom menu (Main Menu is a default system menu):",
      linkToMenuWizard: "(Link to Menus after business creation)",
      linkToMenuBusiness: "(Link to Menus after item creation)",
      pricesUpdate: "Prices Update",
      pricesReset: "Prices Reset",
      takeawayAllowTitle: "Enable Take-Away / Delivery for this item?",
      takeawayAllowIsAlsoForTA: "This item is also for TA and Delivery",
      takeawayAllownotForTA: "This item is for eat-in only",
      badgeNotForTA: "Item not for Take-Away / Delivery",
      badgeForAll: "Eat in / Take-Away / Delivery",
      addCategory: "Create a New Category",
      archiveItemTitle: "Archive this item (Hide from all menus)",
      archiveItemSwitchisVisible: "Item is visible",
      archiveItemSwitchisArchived: "Item is set as Archived",

      wizardImagesPopup: {
        title: "Choose a sample image",
        subtitle:
          "You\'ll always be able to change it later in your item settings",
        // close: "Close",
        // select: "Select",
        // clear: "Clear",
      },
      specialPrice: {
        price: "Sale price:",
        date: "Valid until:",
        hint: "Tap here to set the end date and hour",
        dateTooltip: "Set an expiration date for this special price",
      }
    },
    dashboard: {
      staffCardTitle: "Staff",
      chefs: "Logged in Staff Member:",
      waiters: "Logged in Waiters:",
      emptyStaff: "None are currently logged",
      onboardingCardTitle: "Onboarding",
      onboardingCardText: "Get started the easy way",
      qrBlockTitle: "Create an Order",
      qrBlockSubBtn: "Click for more options",
      qrBlockSubTitle: "4 ways your clients can place their orders",
      settingsBlockTitle: "Overview",
      settingsBlockSubTitle: "All business info and settings",
      settingsBlockDynamicTitle: "Business ID: ",
      settingsBlockButtonTitle: "Update Settings",
      salesBlockTitle: "Sales Transactions",
      salesBlockSubTitle: "Your Stripe payments dashboard is easy to access and manage",
      tipsBlockTitle: "Waiters Tips",
      tipsBlockSubTitle: "Waiters tip summaries",
      reportsBlockTitle: "Sales Charts",
      reportsBlockSubTitle: "All orders and items analytics at a glance",
      reportsBlockDynamicTitle: "Today\'s sales: ",
      reportsBlockButtonTitle: "View Reports",
      appsBlockTitle: "Apps",
      appsBlockSubTitle:
        "Notify your staff on how to download their apps and how to log in",
      appsBlockDynamicTitle: "White label: ",
      appsBlockButtonTitle: "Explore Apps",
      staffBlockTitle: "Staff",
      staffBlockSubTitle: "All your Supervisors, Staff, and Waiters",
      staffBlockDynamicTitle: "Currently logged in: ",
      staffBlockButtonTitle: "(WORK)",
      tableplannerBlockTitle: "Table Planner Booking",
      tableplannerBlockSubTitle:
        "Organize and optimize your restaurant table reservations",
      tableplannerBlockDynamicTitle: "Reservations today: ",
      tableplannerBlockButtonTitle: "(WORK)",
      ordersBlockTitle: "Orders Management",
      ordersBlockSubTitle: "Keep track of all your orders in real time",
      ordersBlockDynamicTitle: "In preparation: ",
      ordersBlockButtonTitle: "(WORK)",
      businessTierBlockTitle: "Upgrade Plans",
      businessTierBlockSubTitle: "Select your Credits plan",
      businessTierBlockDynamicTitle: "Current plan: ",
      businessTierBlockButtonTitle: "(WORK)",
      OnboardingBtn: "Onboarding",
      OnboardingBlockTitle: "Onboarding Stepper",
      OnboardingBlockSubTitle:
        "A step-by-step guide that will help you understand the system components",
      OnboardingBlockDynamicTitle: "Current steps: 12",
      OnboardingBlockButtonTitle: "START",
      qrCodeClientBlockTitle: "Orders QR Code",
      qrCodePrintInputTitle: "Add a title to the print (optional)",
      qrCodeCopiedAlert: "The URL link has been copied",
      availableSoon: "Available Soon!",
      credits: "Current Plan: Credits",
      free: "Current Plan: Free",
      monthlySubscribed: "Current Plan: Unlimited",
      getPaidBlockTitle: "Get Paid",
      getPaidBlockSubTitle: "Enroll to receive payments",
      batchSettings: "Settings",
      batchUtility: "Utility",
      ribbonPaymentProviderNotSet: "Payments are Disabled",
    },
    overview: {
			title: "Overview page",
		},
		apps: {
			title: "Download your Apps",
			subTitle: "Find here all links and login credentials to work efficiently",
			back: "Back to {name}",
			appStore: "App Store",
			getItOn: "GET IT ON",
			googlePlay: "Google Play",
			download: "Download on the",
			appStore: "App Store",
			headerApp: "App",
			headerUsage: "Usage",
			headerLinks: "Download links",
			headerCreds: "Authorized users and login credentials",
			qrLinkSubtitle: "Open in browser",
			qrLinkExplan: "Your business is ready to accept orders with this QR code or by clicking the button below. Share this QR code or URL with your clients",
			kitchen: "PREP App",
			kitchenUsage:
				"Placed in every Prep Station to control new orders and promote preparations",
			kitchenAppGooglePlay:
				"https://play.google.com/store/apps/details?id=com.dreamdiner.kitchen",
			kitchenQrTitle: "chefs title",
			kitchenPrintTitle: "PREP App @Google Play",
			waiters: "Waiter App",
			waitersUsage:
				"Enable your waiters to take client orders and deliver the ready items to clients",
			waitersAppGooglePlay:
				"https://play.google.com/store/apps/details?id=com.dreamdiner.waiter",
			waiterQRTitle: "waiters title",
			waitersPrintTitle: "Waiters App @Google Play",
			supervisors: "Admin App",
			supervisorsUsage:
				"An efficient tool for you or any supervisor to control the current service: orders, cashbox, staff, and more",
			supervisorsAppGooglePlay:
				"https://play.google.com/store/apps/details?id=com.dreamdiner.admin",
			supervisorsWeb: "https://admin.dreamdiner.io",
			supervisorsQrTitle: "supervisors title",
			supervisorsPrintTitle: "Admin App @Google Play",
			clients: "Client App",
			clientsUsage:
				"Here is where your clients can find you: view your menu or make orders",
			clientsAppGooglePlay:
				"https://play.google.com/store/apps/details?id=com.dreamdiner.client.prod",
			clientsAppStore:
				"https://apps.apple.com/us/app/gypsy-ace-2023/id6449999003",
			clientsWeb: "https://client.dreamdiner.io",
			clientsQrTitle: "clients title",
			clientsPrintTitle: "Clients Apps",
			label: "Whitelabel App",
			labelUsage:
				"Your private client app present in Google Play and Apple App Store with only your restaurant items",
			labelAppGooglePlay:
				"https://play.google.com/store/apps/details?id=com.dreamdiner.client.prod",
			labelAppStore: "",
			// Warning! Correct link is "https://dev.client.dreamdiner.io/business/direct/4Cu0vVmfFH"
			labelWeb: "https://dev.client.dreamdiner.io/business/4Cu0vVmfFH",
			labelQrTitle: "label title",
			labelPrintTitle: "label title",
			tablePlanner: "Table Planner",
			tablePlannerUsage:
				"A cross-platform multi-device app that will robust your restaurant reservations control",
			tablePlannerAppGooglePlay:
				"https://play.google.com/store/apps/details?id=com.dreamdiner.planner",
			tablePlannerAppStore:
				"https://apps.apple.com/us/app/restaurant-seating-planner/id1463277281?ls=1",
			tablePlannerQrTitle: "table Planner title",
			tablePlannerPrintTitle: "tablePlanner title",
			userName: "Name",
			userRole: "Role",
			userLogin: "Login",
			userSms: "SMS",
      invite: "Notify {name}",
      inviteSuccess:
        "A WhatsApp invitation to download the app and login was sent successfully :)",
		},
		reports: {
			title: "Reports page",
			startDate: "Start Date",
			endDate: "End Date",
			orders: "Orders",
			sales: "Sales",
			refunds: "Refunds",
			ratings: "Ratings",
			selectY: "Select Y",
			selectX: "Select X",
			displayType: "Display Type",
			totalSales: "Total Sales",
			totalOrders: "Total Orders",
			totalRefunds: "Total Refunds",
			totalRatings: "Total Ratings",
			totalTips: "Total Tips",
			tips: "Tips",
			bar: "Bar",
			line: "Line",
			area: "Area",
			average: "Average",
			showAverage: "Show Average",
			print: "Print",
			export: "Export",
			byDays: "By Days",
			byProducts: "By Items",
			byWaiters: "By Waiters",
		},
		orders: {
      switchOnlyProgress: "In-Progress",
      switchOnlyUnpaid: "UnPaid",
      labelPaid: "PAID",
      labelUnPaid: "UNPAID",
      labelInProgress: "In Progress",
      labelDone: "Done",
      sortBy: "Sort by:",
      perPage: "Per page:",
      byNum: "Order Number",
      byDate: "Order Date",
      allTime: "From all time",
      downloadPrepApp: "Admin and listed Staff Members are invited to download the PREP-APP for a wide range of additional options and features",
      downloadPrepAppLink: "https://dreamdiner.io/prep_app/",
      clientRatingItem: "Item rated by the client as:",
      clientRatingWaiter: "Waiter rated by the client as: ",
      clientTip: "Waiter\'s tip: ",
      clientNoRating: "Found no rating",
      tooltipRefreshOrders: "Refresh Page",
      tooltipRefreshOrdersUpdates: "Updates",
      tooltipRefreshOrdersNew: "New",
      tooltipFullScreen: "Full-screen mode",
      tooltipSwitchInProgress: "Switch to show only orders In-Progress",
      tooltipSwitchUnPaid: "Switch to show only orders UnPaid",
      tooltipSwitchItems: "Show Order",
      tooltipOrderType: "Order type",
      tooltipItemNumber: "Number of items in this order",
      tooltipItemNumberExtra: "Attention! Some items have special options",


      tooltipBtnInProgress: "Click to set an order as DONE",
      tooltipBtnDone: "Click to set back as In Progress",

      tooltipBtnPaidStatusPaid: "Change to UNPAID",
      tooltipBtnPaidStatusUnPaid: "Set as PAID",

      tooltipBtnOrderRated: "Order has been rated by client",
      tooltipBtnOrderRequest: "Client request",














      list: {
				num: "Num.",
        client: "Client",
        tel: "Tel.",
        type: "Type",
        table: "Table",
        sum: "Sum",
        items: "Items",
        remark: "Note",
        payment: "Payment",
        id: "ID",
        time: "Time",
        status: "Preparation"
			},

			tabs: {
				progress: "Progress",
				completed: "Ready",
				delivered: "Delivered",
			},
		},
		onboarding: {
			backToOnboardingBtn: "Continue with the Onboarding",
			// obIntro: {
			// 	title: "Congratulations! Your digital menu is always FREE$$*$$",
			// 	subtitle:
			// 		"All the information you"ve put into the wizard has now been integrated into your very own, state of the art, interactive website.$$*$$",
			// 	text: "Your customers will now be able to use your website to interact with your business in incredible ways, and use it to help them have an even better experience.$$*$$",
			// 	cta: "Click here to see it!$$*$$",
			// },
			// obLocation: {
			// 	title: "How awesome is that?$$*$$",
			// 	subtitle:
			// 		"Your clients can now interact with your business seamlessly through their smartphones.$$*$$",
			// 	text: "They can order, choose options for their own dishes, call their waiter, pay for themselves and others at their table and even leave a tip without any staff help!$$*$$",
			// 	cta: "Wow, That\'s amazing!$$*$$",
			// },
			// obImages: {
			// 	title: "One last surprise$$*$$",
			// 	subtitle: "Have some free stuff!$$*$$",
			// 	text: "Let"s add some credits to your business account to get you started smoothly. Welcome To DreamDiner.$$*$$",
			// 	cta: "Add {num} credits$$*$$",
			// },
			// stepper: {
			// 	newBusiness: { label: "Open a new business" },
			// 	startWizard: { label: "Start the wizard" },
			// 	menuAndItems: { label: "Your menu & items" },
			// 	replaceDemoImages: { label: "Replace demo images" },
			// 	customizeBusinessSettings: {
			// 		label:
			// 			"(Optional) Add or customize all business settings (categories, add items, etc...)",
			// 	},
			// 	firstOrder: { label: "Your first order" },
			// 	visitMiniSite: { label: "Visit your mini-site and place an order" },
			// 	getOrder: { label: "Get your order at your business email" },
			// 	checkOrder: { label: "Check your order" },
			// 	seeYourOrder: { label: "(Optional) See your order" },
			// 	exploreReports: { label: "Explore reports" },
			// 	visitReports: { label: "(Optional) Visit reports to see your order" },
			// 	tryKitchen: { label: "Try PREP App" },
			// 	downloadKitchenApp: {
			// 		label:
			// 			"(Optional) Download PREP App and manage your order from there!",
			// 	},
			// 	enrollPayments: { label: "Enroll to get paid" },
			// 	getPayments: {
			// 		label: "(Optional) Checkout how you can get payments",
			// 	},
			// 	startWorking: { label: "Start working!" },
			// 	businessQR: { label: "Your business QR code" },
			// 	clientApps: { label: "Android or iOS Client apps" },
			// 	whitelabel: { label: "Your whitelabel" },
			// },
			//ODEDA OB set here
			onboarding_cards: {
        // 1
				obQR: {
					title: "Create your first order!",
					description: "Scan the QR code with your mobile, or click this card for more options.",
					next_cta: "Next",
					prev_cta: "Back",
					// label: "Take me there",
				},

        // 2
				obOrder: {
					title: "Here\'s your order!",
					// subtitle: "Subtitle not in use here",
					description: "After order has been submitted, see it here on your dashboard. Try changing the Preparation and Payment statuses.",
          label: "Take me there",
					next_cta: "Next",
					prev_cta: "Back",
					// gps_coordinates_link: "How to find the GPS coordinates",
				},

        // 3
        obReports: {
					title: "Explore Reports",
					// subtitle: "Subtitle not in use here",
					description: "Real-time data of all activity at a glance! Even the order you have just made...",
          label: "Show me Reports",
					next_cta: "Next",
					prev_cta: "Back",
				},

        // 4
        obKitchen: {
					title: "DreamDiner Prep-App",
					subtitle: "Available for Android devices",
					description: "(Optional) Download the Preparation App for Android. Your assigned staff members are invited to log in!",
					next_cta: "Next",
					prev_cta: "Back",
					label: "App link and staff members",
					// kitchen_app_link: "Download app on Google Play",
					// loginWithNumber: "And login with:",
				},

        // 5
        obSettings: {
					title: "Business Settings",
					// subtitle: "Subtitle not in use here",
					description: "Review all your business settings and update your location",
					next_cta: "Next",
					prev_cta: "Back",
					label: "Take me there",
				},

        // 6
				obImages: {
					title: "Replace Demo Images",
					// subtitle: "Subtitle not in use here",
					description: "(Optional) If you used the demo images, maybe now replace them with your real images?",
					next_cta: "Next",
					prev_cta: "Back",
					label: "Take me to my items to replace images",
				},

        // 7
				obOptions: {
					title: "Link Item Options",
					// subtitle: "Subtitle not in use here",
					description: "(Optional) Your item options and extras: link them to any needed items",
					next_cta: "Next",
					prev_cta: "Back",
					label: "Take me to my items to update options",
				},

        // 8
        obMenu: {
					title: "Menu item prices",
					// subtitle: "Subtitle not in use here",
					description: "(Optional) Set different item prices according to your menus (Skip if you did not open any Menus)",
					next_cta: "Next",
					prev_cta: "Back",
					label: "Take me to my items to link menus",
				},

        // 9
        obTables: {
					title: "Table options",
					// subtitle: "Subtitle not in use here",
					description: "Explore the options for presenting tables and their coding on the client side.",
					next_cta: "Next",
					prev_cta: "Back",
					label: "Take me to Table options",
				},

        // 10
        obWaiters: {
					title: "Assign Waiters",
					// subtitle: "Subtitle not in use here",
					description: "(Optional) If you have waiters, make sure they are assigned to their respective tables (Click the Assign Tables to Waiters button)",
					next_cta: "Next",
					prev_cta: "Back",
					label: "Take me to my waiters",
				},

        // 11
        obPlans: {
					title: "Upgrade Plans",
					// subtitle: "Subtitle not in use here",
					description: "Set your business Credits plan and Get Paid plan",
					next_cta: "Next",
					prev_cta: "Back",
					label: "Finish",
					learnMore: "Learn more here:",
					business_models_link: "Show me Upgrade Plans",
				},

        // 12
				obCategories: {
					title: "4. Update Category icons",
					// subtitle: "Subtitle not in use here",
					description: "Set your categories, icons or images, exactly as you want them!",
					next_cta: "Next",
					prev_cta: "Back",
					label: "Take me there",
				},
				// obOrder: {
				// 	title: "8. Time to make an order!",
				// 	subtitle: "Download app or go to URL",
				// 	description:
				// 		"Try the Client App to make your first order, to see the business you have just opened and your menu items!",
				// 	next_cta: "Next",
				// 	prev_cta: "Back",
				// 	label: "Take me there",
				// 	client_app_link: "Click here for your client URL",
				// },

      // 13
				obLive: {
					title: "Set Business to LIVE",
					// subtitle: "Subtitle not in use here",
					description: "Busines is ready yo accept orders? Congrats! Set the switch to LIVE.",
					next_cta: "Next",
					prev_cta: "Back",
					label: "Take me there",
				},

				noshowDialog: {
					title: "Exit the OnBoarding?",
					explan: "It is strongly recommended to complete the onboarding wizard, at least once, to set up all links and images correctly for perfect functionality",
					label: "Tag here not to show the OnBoarding again",
				},
			},
		},
		sections: {
			dashboard: "Dashboard",
			info: "Settings",
			hours: "Activity Hours",
			supervisors: "Supervisors",
			tables: "Tables",
			waiters: "Waiters",
			kitchens: "Prep Stations",
			chefs: "Staff",
			categories: "Categories",
			menus: "Menus",
			items: "Items",
			extras: "Item Options",
			groupOptions: "Group Options",

      infoExplan: "All Business Info & Settings",
			hoursExplan: "When are you Open? Closed?",
			supervisorsExplan: "Manage your Supervisors",
			tablesExplan: "Add and edit your Tables",
			waitersExplan: "Edit Waiters and assign to Tables",
			kitchensExplan: "Your Preparation Stations",
			chefsExplan: "Manage your Staff",
			categoriesExplan: "Add and edit your Categories",
			menusExplan: "Add and edit your Menus",
			itemsExplan: "Add and edit your Items",
			extrasExplan: "Add and edit your Item Options",
			groupOptionsExplan: "Add and edit your Group Options",
		},
		toolbar: {
			creditsBannerText:
				"ALERT: You have only {credits} credits remaining! Your defined minimum is: {minimum}",
			creditsBannerCta: "Top-up business credits here",
			credits: "Credit balance: {credits}",
			miminum: "Alert when under: {miminum}",
			helpTitle: "Support",
			helpTooltip: "Support chat",
			supportPopupTitle: "Scan the QR code",
			supportPopupOr: "Or simply click to reach us:",
			supportPopupBtn: "Start a support chat",
			supportPopupSupportRemark: "Our Support Centre Team is available from 8:00 a.m. to 4:00 p.m., SUN-FRI (UTC+2). We\'re here to answer questions about setting up your business to get started and for any questions or issues after that!",
			supportHelpScreenshot: "Please help us by providing and enclosing a screenshot or a short clip that best describes your issue",

			breadcrumbs: {
				home: "My Businesses",
				info: "Business info",
				dashboard: "Dashboard",
				qr: "Create Order Options",
				overview: "Overview",
				apps: "Apps",
				reports: "Sales charts",
				tips: "Tips summaries",
				orders: "Manage Orders",
				onboarding: "Onboarding",
				supervisors: "Supervisors",
				tables: "Tables",
				waiters: "Waiters",
				kitchens: "Prep Stations",
				chefs: "Staff",
				categories: "Categories",
				menus: "Menus",
				items: "Items",
				extras: "Item Options",
				edit: "Edit Object",
				hours: "Hours",
				groups: "Group Options",
				purchases: "Purchases",
				planner: "Table Planner",
				plans: "Upgrade Plans",
				sales: "Sales Transactions",
        credits: "Get more credits",
				subscription: "FIX Plan Subscription",
				// getPaid: "Go Unlimited",
				payments: "Enrol to get paid"
			},
		},
		fromWizard: {
			title: "Your New Business {title} is Ready!\n500 credits have been added to your credit bank (top right corner)",
			titleDev: "Your New Business {title} is Ready!\n Only 5 credits have been added (top right corner)\nso please test Free mode and Purchase Credits",
			text: "Expect a Welcome Email sent to: {email} (check spam!) with some important details.\nNext stage is a friendly Onboarding tutorial to help you set up your business perfectly. Enjoy!",
			btn: "Start the Onboarding",
		},
	},

	landing: {
		welcome: "Hello, {user}. Great to see you again!",
		lastUpdated: "Last update: {date}",
		beta: "BETA",
		wizardTitle: "Create a New Business",
		wizardDescription: "A friendly wizard will guide you throughout",
		demoBusinessDescription: "The place to tryout all features",
		emptyBusinessTitle: "Bring your Dining Business to Life in just Minutes.",
		emptyBusinessSubTitle: "A friendly wizard will help you to set up the basics",
	},
	multiSelect: {
		placeholder: "Select one or more",
		empty: "The list is currently empty",
		skipped: "Skipped (but default value added)",
		onlyOption: "Currently, This is the only available option here",
	},
	demoBusiness: {
		demoMode: "For Demo purposes only. Save is disabled",
		showdemo: "Show DEMO Business",
		hidedemo: "Hide DEMO Business",
	},
	nav: {
		login: "Log in",
		logout: "Log out",
		user: {
			guest: "Welcome, Guest!",
			btn: "Your profile",
			editProfile: "Edit profile",
		},
		business: {
			demoBusinessTitle: "Demo Business",
			enterBusiness: "Enter Business",
			demoBusinessBtn: "Live Demo",
		},
	},
	wizard: {
		dialog: {
			title: "You are back to the saved version of your business creation draft.",
			subtitle: "Would you like to continue where you left off?",
			confirm_title: "Warning!",
			confirm_subtitle:
				"Restarting the wizard will revert everything you have done so far and erase all your changes. Are you sure you want to start from scratch?",
			confirm_cta: "Sure, reset everything",
			confirm_back: "Nope, go back",
		},
		suggestions: {
			option_01: "Instantly add from a given template",
			option_02: "Add your own from scratch",
			toggle: "Show suggestions",
			add: "Add from template",
			addTemplateBtn: "Select this template",
			skipNextBtn: "Skip for now",
		},
		toolbar: {
			saved: "Saved as Draft",
			typeRequired: "This step is required for all business types",
			typeChecked: "This stage is recommended for this business type",
			required: "This stage is required",
			mobileToolbarProgressNav: "Click the circle to navigate",
			titles: {
				intro: "Starting a New Business Wizard",
				types: "Types",
				customize: "Your Wizard Stages:",
				info: "Info",
				supervisors: "Supervisors",
				tables: "Tables",
				waiters: "Waiters",
				kitchens: "Prep Stations",
				chefs: "Staff",
				categories: "Categories",
				menus: "Menus",
				extras: "Item Options",
				items: "Items",
				terms: "Terms & Conditions",
				summary: "Summary",
			},
			btns: {
				createBusiness: "Create My Business!",
			},
		},
		util: { seeMore: "See all: {num}", listEmpty: "List is currently empty :-0" },
		intro: {
			title: "In case you want to learn some more...\r\n",
			subtitle:
				"",
			redLine: "Enjoy your DreamDiner experience!",
			title2: "A short explanatory video to start your experience (45 sec.)",
			subtitle2:
				"An animated explainer clip shows the business creation process",
			redLine2: "2 minutes is all it takes :)",
		},
		customize: {
			title: "What is your business type?",
			subtitle:
				"Select a business template to help the system adjust only the relevant features needed for your type.\nTo customize manually or explore all features, select Custom.",
      clickNext:
       "To continue, click the Next button on the top right corner",
      typeHeader:
				"Here are the wizard steps recommended for your business type:",
      explan:
				"To change the business type, click Back",
			bottom:
				"No worries: Once your business is created, any skipped feature can be then added again to your business",
      businessTypeOtherExplan: "In this type of business, you can check or uncheck any section for full customization.",
			businessTypes: {
				truck: { title: "Food Truck", subtitle: "" },
				pub: { title: "Bar / Pub", subtitle: "" },
				fine: { title: "Restaurant", subtitle: "" },
				small: { title: "Small Diner", subtitle: "" },
				cafe: { title: "Cafe", subtitle: "" },
				fast: { title: "Fast Food", subtitle: "" },
				buffet: { title: "Buffet", subtitle: "" },
				popup: { title: "Pop-up", subtitle: "" },
				ghost: { title: "Ghost Kitchen", subtitle: "" },
				delivery: { title: "TA/Delivery", subtitle: "" },
				kiosk: { title: "Kiosk", subtitle: "" },
				other: { title: "Custom", subtitle: "" },
			},
			skippable: "Optional",
			points: {
				types: {
					title: "Business Type",
					subtitle: "Define your business",
					time: "1/2 min",
				},
				customize: {
					title: "Business Steps",
					subtitle: "Customize your own business",
					time: "1/2 min",
				},
				info: {
					title: "Business Details",
					subtitle: "Your restaurant name, description, logo, password, etc.",
					time: "2 min",
				},
				supervisors: {
					title: "Supervisors",
					subtitle: "Assign your restaurant supervisors (Admin is only you!)",
					time: "1 min",
				},
				tables: {
					title: "Tables",
					subtitle: "Add your first table and seats",
					time: "1 min.",
				},
				waiters: {
					title: "Waiters",
					subtitle: "Add your waiters and assign them to tables",
					time: "1 min.",
				},
				kitchens: {
					title: "Prep Stations",
					subtitle: "Your preparation stations: e.g., Grill, Salads, Bar etc.",
					time: "30 sec.",
				},
				chefs: {
					title: "Staff Members",
					subtitle: "Your team: chefs, bartenders, dishwasher, etc.",
					time: "1 min.",
				},
				categories: {
					title: "Categories",
					subtitle: "Add your items\' categories",
					time: "30 sec.",
				},
				menus: {
					title: "Menus",
					subtitle: "E.g. Breakfast Menu, Happy Hour Menu, etc.",
					time: "2 min.",
				},
				extras: {
					title: "Options / Extras",
					subtitle: "E.g. No salt, Extra olive oil, Rare or Well-Done, etc.",
					time: "1 min.",
				},
				items: {
					title: "Items",
					subtitle: "Now you can create and link your first item or dish!",
					time: "3 min.",
				},
				terms: {
					title: "Terms & Conditions",
					subtitle: "Read and approve our terms & conditions",
					time: "+1 min.",
				},
			},
		},
		business: {
			// title: "Business Name",
			// titlePlaceholder: "Your business name",
			// email: "Business Email",
			// emailSubtitle:
			// 	"Your Business Contact Email is essential for system communications and keeps you updated: important notices and changes, new features, exciting tutorial videos, and much more.",
			// emailPlaceholder: "Your business email",
			// emailVerifyText: "OK. Your business mail is the same as your user email",
			// emailVerifyBtn: "Verify now",
			// useMyEmail: "Use my login email",
			// pin: "Admin PIN code",
			// pinSubtitle:
			// 	"This 6 digit code will help authorize special actions in your business",
			// pinPlaceholder: "Numbers only",
			// social: "Online Presence:",
			// website: "Your Business Website",
			// websitePlaceholder: "http://...",
			// country: "Country",
			// timezone: "Timezone",
			// description: "Business Description",
			// optional: "optional",
			// verification: "Verfication needed",
			// currency: "Currency",
			// hours: "Business Hours",
			// adminPassword: "Admin PIN Code",
			// adminPasswordSubtitle:
			// 	"Your PIN is used in many features throughout the system; it can be easily changed daily to give permissions to who can perform and execute various tasks and features. For example: refunding an order, deleting an item, manually logging out a staff member, etc. It is recommended to change this 6-digit password frequently in the Settings.",
			// adminPasswordPlaceholder: "6 digits minimum, letters and numbers",
			// adminPasswordRepeatPlaceholder: "Repeat password",
			// businessLogo: "Business Logo",
			// businessLogoSubtitle:
			// 	"Your logo is presented throughout the Client App and in many other places. Your logo is your business mirror - upload a good one :) If left empty - the system will show the DreamDiner logo. Business Logo will also be the default image in all items which do not have a photo or an uploaded image",
			// logoSelectDemoBtn: "Select Demo Logo",
			// logoSelectOr: "Or:",
			// logoSelectTitle: "Select from a given demo logo",
			// logoSelectSubtitle: "Later, you will replace it with your own logo",
		},

		terms: {
			title0: "OK, so what is next?",
			subtitle0: "In the next step, you will confirm your business before its creation. Need to make any changes? No problem; go back and change; all you did was save. Once created, scan the generated QR code to make your first order. Cool!\nWe will reward your business with 500 FREE credits to make 500 orders to test DreamDiner before you choose your business plan to continue.\nFollow the onboarding guide to make your business even better, and you are good to go LIVE!",
      title: "Our Terms & Conditions",
			description:
				"We are almost there, and you are good to go! One last step before we open your unique business in the DreamDiner system please carefully read and agree to the following topics:",
			redLine: "Thank you for reading and confirming by checking all boxes :)",
			termsCheckbox: "I Agree to the",
			termsLink: "Terms and Policy",
			paymentCheckbox:
				"Getting paid and payment setup options are listed in Settings",
			idleCheckbox: "Business deletion within 90 days if no activity is made",
		},
		summary: {
			businessCreationTitle: "Is your business ready for creation?",
			businessCreationSubTitle: "Here is what you told us about creating your basic business. Feel free to return to any step you need or do that after the business is created.",
			businessCreationClickNext: "To continue, click the CREATE MY BUSINESS button on the top right corner",
			businessCreation3only: "Showing only the first three elements in each section",
			staff: "Team",
			supervisors: "Supervisors",
			waiters: "Waiters",
			chefs: "Staff",
			equipment: "Place",
			kitchens: "Prep Stations",
			tables: "Tables",
			products: "Menus & Items",
			extras: "Extras",
			menus: "Menus",
			categories: "Categories",
			items: "Items",
			regularOptions: "Regular Options",
			groupOptions: "Group Options",
			extraOptions: "{num} Options",
			addRegularOption: "Add Regular Option",
			addGroupOption: "Add Group Option",
			finalize: "Start Your Business",
			working: "Creating your Business",
			patience: "Please wait...",
			saving: {
				info: "Saving now our Business...",
				supervisors: "Updating Supervisors",
				tables: "Updating Tables",
				waiters: "Updating Waiters",
				kitchens: "Updating Prep Stations",
				chefs: "Updating Staff",
				categories: "Updating Categories",
				menus: "Updating Menus",
				extras: "Updating Item Options",
				items: "Updating Items",
			},
		},
		steps: {
			info: "Info",
			types: "Types",
			customize: "Steps",
			supervisors: "Supervisors",
			tables: "Tables",
			waiters: "Waiters",
			kitchens: "Prep Stations",
			chefs: "Staff",
			categories: "Categories",
			menus: "Menus",
			extras: "Options",
			items: "Items",
			terms: "Terms",
			summary: "Summary",
		},
	},
	validation: {
		required: "This input is required",
		email: "Invalid email address",
		minLength: "Must be {min} characters or longer",
		maxLength: "Must be {max} characters or shorter",
		specificLengthDigits: "Must be {number} digits",
		sameAsEmail: "Emails mismatch",
		sameAsPassword: "Passwords mismatch",
		maxValue: "Must be less than {max}",
		minValue: "Must be more than {min}",
		toAfterFrom: "End time must be later than start time",
		isUniquePhone: "This phone is already used here",
		isUniqueTitle: "This title is already used here",
		isUniqueSymbol: "This symbol is already used here",
		url: "Must be a valid URL",
		mustBeInFuture: "There must be a time in the future",
		numeric: "Must be a number",
		dataNotValid: "Data entered not valid",
	},
	hours: {
		default: "Default",
		sunday: "Sunday",
		monday: "Monday",
		tuesday: "Tuesday",
		wednesday: "Wednesday",
		thursday: "Thursday",
		friday: "Friday",
		saturday: "Saturday",
	},
	warnings: {
		// veganItem: "Vegan Item",
		// spicyItem: "Spicy Item",
		hot: "Very Hot",
		organic: "Organic",
		special: "Special",
		spicy: "Spicy",
		vegan: "Vegan",
		longPreparation: "Long Preparation",
		glutenFree: "Gluten Free",

		crustacean: "Crustacean",
		fish: "Fish",
		egg: "Egg",
		gluten: "Gluten",
		celery: "Celery",
		nuts: "Nuts",
		milk: "Milk",
		peanuts: "Peanuts",
		sesame: "Sesame",
		molluscs: "Molluscs",
		lupins: "Lupins",
		mustard: "Mustard",
		mushroom: "Mushroom",
		corn: "Corn",
		soya: "Soya",
		sulphite: "Sulphite",
	},
	imageCropper: {
		cropperTitle: "Upload an Image",
		cropperSubTitle: "If too big, try to adjust the slider and image crop size",
		imageSize: "Current image size: ",
		imageQuality: "Image Quality: ",
		maxImageSize: "Max. image size: ",
		calcOnCrop: "[On Crop]",
		replaceImageBtn: "Add / Replace",
		cropImageBtn: "Save",
		reduceSizeHint: "Reduce image size by changing the image quality value: 0.01 to 1.0.",
	},
	imagePicker: {
		default: "Drag and drop a file here or\r\nbrowse to upload",
		wizard:
			"Upload an image for this item",
	},
  kitchenRoles: {
    chef: "Main Chef",
    sousChef: "Sous Chef",
    grillChef: "Grill Chef",
    bartender: "Bartender",
    kitchenAssistant: "Kitchen Assistant",
    other: "Other"
  },

  quickGuide: {
    title: "How To?",
    q1: "Manage my orders",
    q1opt01a: "Admin Dashboard > Order Management card",
    q1opt01b: "Here you can promote the whole order to Ready, then set it as PAID",
    q1opt02a: "Use the Prep-App",
    q1opt02b: "Recommended: lots of extra features available to manage orders. Log in using any staff member\'s mobile number.",
    q1opt03a: "By email",
    q1opt03b: "Sent to the business email address specified in Dashboard > Overview > Settings > Business email",

    q2: "Refund an item",
    q2opt01a: "Order active: use the Prep-App",
    q2opt01b: "Choose between receiving a regular refund or opting for an \"On-The-House\" reimbursement. In either case, the client will receive a notification through a popup and a message.",
    q2opt02a: "Order completed: with Payments Provider Dashboard",
    q2opt02b: "Login to your dashboard, find the transaction, click on the line, and send a refund.",

    q3: "Add a general item",
    q3ans: "A general item (an item that is not listed on your menu) can be done with the Prep-App > click the order + icon > add general item",
    q4: "How to calculate the waiter's tips?",
    q4ans: "All waiters\' tips are in the Dashboard > Waiters Tips card. Set the waiter and day, or even export a CSV (EXCEL) file",

  },


  explanations: {
    supervisorStep01___Wizard: "Name and email will be the Supervisor\'s username to log into the admin dashboard",
    supervisorStep01_Business: "Name and email will be the Supervisor\'s username to log into the admin dashboard",
    supervisorStep02___Wizard: "The mobile number will be the Supervisor\'s username to log into the PREP-APP (more info later)",
    supervisorStep02_Business: "The mobile number will be the Supervisor\'s username to log into the PREP-APP (Go to: Dashboard > Apps)",

    tableStep01___Wizard: "Each table must have a name and the number of seats",
    tableStep01_Business: "Each table must have a name and the number of seats",
    tableStep02___Wizard: "Assign a color for this table",
    tableStep02_Business: "Assign a color for this table",
    tableStep03___Wizard: "Set a Table Code", //Not in Wizard
    tableStep03_Business: "Set a Table Code",

    waiterStep01___Wizard: "Name and mobile number will be the Waiter\'s username to log into the waiter app",
    waiterStep01_Business: "Name and mobile number will be the Waiter\'s username to log into the waiter app",
    waiterStep02___Wizard: "All tables - and orders sent from these tables - will be marked with this selected waiter\'s color for easy delivery.",
    waiterStep02_Business: "All tables - and orders sent from these tables - will be marked with this selected waiter\'s color for easy delivery.",
    waiterStep03___Wizard: "(Optional) The client will be able to view the waiter\'s photo and name after placing an order and on the payment page for leaving a tip.", //Not in Wizard
    waiterStep03_Business: "(Optional) The client will be able to view the waiter\'s photo and name after placing an order and on the payment page for leaving a tip.",
    waiterStep04___Wizard: "Check which tables will be assigned to this waiter.", //Not in Wizard
    waiterStep04_Business: "Check which tables will be assigned to this waiter.",

    terminalStep01___Wizard: "Keep the given name as short and concise as possible",
    terminalStep01_Business: "Keep the given name as short and concise as possible",
    terminalStep02___Wizard: "The given color will color all ordered items in the PREP-APP to easily distinguish their assigned station",
    terminalStep02_Business: "The given color will color all ordered items in the PREP-APP to easily distinguish their assigned station",

    staffStep01___Wizard: "Name and mobile number will be the Staff Member\'s username to log into the PREP-APP",
    staffStep01_Business: "Name and mobile number will be the Staff Member\'s username to log into the PREP-APP",
    staffStep02___Wizard: "The given color will color all ordered items this Staff Member will make for clients in the PREP-APP",
    staffStep02_Business: "The given color will color all ordered items this Staff Member will make for clients in the PREP-APP",
    staffStep03___Wizard: "Assign a role and a preparation station",
    staffStep03_Business: "Assign a role and a preparation station",
    staffStep04___Wizard: "A photo is optional but useful",
    staffStep04_Business: "A photo is optional but useful",

    categoryStep01___Wizard: "Each category must include a name and a default background color",
    categoryStep01_Business: "Each category must include a name and a default background color",
    categoryStep02___Wizard: "You can upload your icon or image or choose from the provided set. Icons and images are always changeable and updatable",
    categoryStep02_Business: "You can upload your icon or image or choose from the provided set. Icons and images are always changeable and updatable",

    menuStep01___Wizard: "Each Menu must include a name, a start, and an end time. When you set your items, you can link them to different Menus. However, setting different prices for items on different menus is only enabled after creating the business. If a menu is active during its specified start and end times, the price set for items on that menu will override their regular prices.",
    menuStep01_Business: "Each Menu must include a name, a start, and an end time. Don\'t forget to go to Items and connect the appropriate items to this Menu. If a menu is active during its specified start and end times, the price set for items on that menu will take precedence over their regular prices. By default, all items are automatically added to a new menu until you manually deselect them in the Items section.",
    menuStep02___Wizard: "The assigned color is only for the visual representation of the menus on the chart. Avoid menus overlapping.",
    menuStep02_Business: "The assigned color is only for the visual representation of the menus on the chart. Avoid menus overlapping.",

    optionsDoThis___Wizard: "Provide a title for each option. \nCheck the box if it\'s free, or uncheck it to set a price that will be added to the item\'s price when ordered. Later, you will be able to link your items to this option.",
    optionsDoThis_Business: "Provide a title for each option. \nCheck the box if it\'s free, or uncheck it to set a price that will be added to the item\'s price when ordered. \nRemember to link this option to the relevant items needed.",

    groupOptionsStep01___Wizard: "Provide a title for the group option.",  //Not in Wizard
    groupOptionsStep01_Business: "Provide a title for the group option.",
    groupOptionsStep02___Wizard: "Check the box if it\'s free, or uncheck it to set a price. \nBy default, the top option is auto-selected on the clients\' side. Drag and drop to rearrange the order of all options inside the group.", //Not in Wizard
    groupOptionsStep02_Business: "Check the box if it\'s free, or uncheck it to set a price. \nBy default, the top option is auto-selected on the clients\' side. Drag and drop to rearrange the order of all options inside the group.",

    itemsStep01___Wizard: "Keep the name brief and appealing. Prep Code is the item hint code used in the PREP APP (optional).",
    itemsStep01_Business: "Keep the name brief and appealing. Prep Code is the item hint code used in the PREP APP (optional).",
    itemsStep02___Wizard: "Pricing: Specify the listed price, and return here after creating the business to set the sale price and menu prices if necessary.",
    itemsStep02_Business: "Remember the rule: A menu price is stronger than the listed price, and the sale price supersedes them both.",
    itemsStep03___Wizard: "The Options section opens after the creation of the business.",
    itemsStep03_Business: "Select which Options and Group Options are relevant for this item (multi-selection allowed for both Regular and Group Options)",
    itemsStep04___Wizard: "This is optional but important! Please attach allergen icons (e.g., Sesame, Nuts, etc.) and labels (e.g., Vegan, Long Prep, Chef Special, etc.).",
    itemsStep04_Business: "This is optional but important! Please attach allergen icons (e.g., Sesame, Nuts, etc.) and labels (e.g., Vegan, Long Prep, Chef Special, etc.).",
    itemsStep05___Wizard: "Item description and image upload",
    itemsStep05_Business: "Item description and image upload",
    itemsStep06___Wizard: "Link this item to at least one Category (required) and to a Prep Station (optional)",
    itemsStep06_Business: "Link this item to at least one Category (required) and to a Prep Station (optional)",
    itemsStep07___Wizard: "The Special Preferences section opens after the creation of the business.",
    itemsStep07_Business: "Preferences",

    },

  suggestions: {
    tables: [
      {
        ref: 'bar',
        title: 'Bar',
        seats: 2,
        color: '#ff7700'
      },
      {
        ref: 'vip',
        title: 'VIP',
        seats: 12,
        color: '#ffaf00'
      },
      {
        ref: 'tb1',
        title: 'TAB1',
        seats: 4,
        color: '#0c67e9',
        default: true
      },
      {
        ref: 'tb2',
        title: 'TAB2',
        seats: 4,
        color: '#6f46a8'
      }
    ],
    extras: [
      {
        ref: 'noSalt',
        title: 'No Salt',
        price: 0,
      },
      {
        ref: 'noOnion',
        title: 'No Onions',
        price: 0,
      },
      {
        ref: 'extraKetchup',
        title: 'Extra Ketchup',
        price: 0.5,
      },
      {
        ref: 'toGo',
        title: 'Wrap TO GO',
        price: 0,
      },
      // {
      // 	ref: 'preperation',
      // 	title: 'Preperation Level',
      // 	price: 0,
      // 	extras_group: [
      // 		{
      // 			title: 'Medium Rare',
      // 			price: 0
      // 		},
      // 		{
      // 			title: 'Medium',
      // 			price: 0
      // 		},
      // 		{
      // 			title: 'Medium Well',
      // 			price: 0
      // 		},
      // 		{
      // 			title: 'Well Done',
      // 			price: 0
      // 		}
      // 	]
      // },
      // {
      // 	ref: 'sideDish',
      // 	title: 'Select One Side Dish',
      // 	price: 0,
      // 	extras_group: [
      // 		{
      // 			title: 'Rice',
      // 			price: 0
      // 		},
      // 		{
      // 			title: 'Fries',
      // 			price: 0
      // 		},
      // 		{
      // 			title: 'Spaghetti',
      // 			price: 0
      // 		},
      // 		{
      // 			title: 'Chili con carne',
      // 			price: 2.5
      // 		}
      // 	]
      // },

    ],
    items: [
      // {
      // 	ref: 'beer',
      // 	title: 'Beer Pint',
      // 	symbol: 'BRP',
      // 	description: 'A cold, refreshing pint of beer',
      // 	image: ItemImages[1],
      // 	price: '20',
      // 	kitchen_terminal: null,
      // 	take_away: true,
      // 	archive: false
      // },

      // Set images as appear on demoitemimages.js. Remember the first = 0
      {
        ref: 'coffee',
        title: 'Cup of Coffee',
        symbol: 'COC',
        description: 'Our coffee is undoubtedly the best way to start your day, and our delicious, fresh breakfast and lunch options will satisfy your appetite.',
        image: ItemImages[15],
        price: '4.5',
        kitchen_terminal: null,
        take_away: true,
        archive: false
      },
      {
        ref: 'burger',
        title: 'Cheeseburger',
        symbol: 'CBG',
        description: 'A perfectly soft bun, quality, juicy meat that is seasoned well, plenty of cheese, caramelized onions and a really good secret sauce are the key to making a our truly classic cheeseburger',
        image: ItemImages[1],
        price: '7.99',
        kitchen_terminal: null,
        take_away: true,
        archive: false
      },
      {
        ref: 'salad',
        title: 'Fresh Salad',
        symbol: 'SLD',
        description: 'Cherry tomatoes and crunchy greens',
        image: ItemImages[5],
        price: '25',
        kitchen_terminal: null,
        take_away: true,
        archive: false
      },
      {
        ref: 'pizza',
        title: 'Mozzarella Pizza',
        symbol: 'MZP',
        description: 'This classic mozzarella pizza is simple and delicious! Made with fresh mozzarella, tomatoes, and basil',
        image: ItemImages[4],
        price: '25',
        kitchen_terminal: null,
        take_away: true,
        archive: false
      }
    ],
    categories: [
      {
        ref: 'burgers',
        title: 'Burgers',
        color: '#ff7700',
        image: 'https://s3.amazonaws.com/digidine.images/DD_appimages_categories/set_02/noun_cat_26.png',
        default: true
      },
      {
        ref: 'starters',
        title: 'Starters',
        color: '#ffaf00',
        image: 'https://s3.amazonaws.com/digidine.images/DD_appimages_categories/set_02/noun_cat_11.png',
      },
      {
        ref: 'sides',
        title: 'Sides',
        color: '#8c99a7',
        image: 'https://s3.amazonaws.com/digidine.images/DD_appimages_categories/set_02/noun_cat_24.png',
        default: true
      },
      {
        ref: 'coldDrinks',
        title: 'Cold Drinks',
        color: '#00afd8',
        image: 'https://s3.amazonaws.com/digidine.images/DD_appimages_categories/set_02/noun_cat_02.png',
        default: true
      }
    ],
    kitchens: [
      {
        ref: 'main',
        title: 'Main Prep',
        color: '#003250',
        default: true,
        main: true
      },
      {
        ref: 'grill',
        title: 'Grill',
        color: '#ff2644'
      },
      {
        ref: 'prep',
        title: 'Preperations',
        color: '#6f46a8'
      },
      {
        ref: 'bar',
        title: 'Bar',
        color: '#00afd8'
      }
    ],
    menus: [ //menu colors change here from selectableColors > Color Pallette
      {
        ref: 'main',
        title: 'Main Menu',
        from: '00:01',
        to: '23:59',
        color: '#000000',
        items_with_special_price: {},
        default: true
      },
      {
        ref: 'breakfast',
        title: 'Breakfast Menu',
        from: '08:00',
        to: '11:00',
        color: '#00afd8',
        items_with_special_price: {}
      },
      {
        ref: 'lunch',
        title: 'Lunch Menu',
        from: '12:00',
        to: '16:00',
        color: '#00a05b',
        items_with_special_price: {}
      },
      {
        ref: 'happyHour',
        title: 'Happy Hour Menu',
        from: '17:00',
        to: '18:00',
        color: '#ff2644',
        items_with_special_price: {}
      },
      {
        ref: 'dinner',
        title: 'Dinner Menu',
        from: '18:01',
        to: '20:00',
        color: '#ff7700',
        items_with_special_price: {}
      }
    ]
  },

	currency: {
		name: "Dollar",
		abv: "USD",
		prefix: "$",
		suffix: "",
		make: "${num}",
	},
	currencies: {
		usd: { name: "U.S. Dollar (USD)", symbol: "$" },
		eur: { name: "Euro (EUR)", symbol: "€" },
		ils: { name: "Israeli (NIS)", symbol: "₪" },
    gbp: { name: "Pound Sterling (GBP)", symbol: "£" }
	},
  models: {
		category: { singular: "category", plural: "categories" },
		chef: { singular: "chef", plural: "chefs" },
		extra: { singular: "extra", plural: "extras" },
		item: { singular: "item", plural: "items" },
		kitchen: { singular: "kitchen", plural: "kitchens" },
		menu: { singular: "menu", plural: "menus" },
		supervisor: { singular: "supervisor", plural: "supervisors" },
		table: { singular: "table", plural: "tables" },
		waiter: { singular: "waiter", plural: "waiters" },
	},
}
